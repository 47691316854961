.notfound-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
}

.notfound-container a {
  text-decoration: none;
}

.notfound-container a button {
  border-radius: 0;
  width: max-content;
}
