@font-face {
  font-family: "AkzidGroLigOsF";
  src: local("AkzidGroLigOsF"),
    url("../public/assets/font/AkzidGroLigOsF.ttf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "AkzidGroReg";
  src: local("AkzidGroReg"),
    url("../public/assets/font/AkzidGroReg.ttf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "AkzidGroMed";
  src: local("AkzidGroMed"),
    url("../public/assets/font/AkzidGroMed.ttf") format("opentype");
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
  font-family: "AkzidGroLigOsF";
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none; /* For Firefox */
}

/* Hide scrollbar for IE and Edge */
* {
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

@media only screen and (min-width: 0px) {
  .navbar {
    width: 100%;
    background-color: white;
    color: black;
  }

  .navbar .navbar-massaia {
    display: none;
  }

  .navbar .navbar-menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 7%;
    width: 10vw;
    height: auto;
    z-index: 1;
  }
}

@media only screen and (min-width: 590px) {
  .navbar .navbar-massaia {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 6%;
    font-family: "AkzidGroLigOsF";
    font-size: 6vw;
    font-weight: normal;
  }

  .navbar .navbar-menu {
    margin: 6%;
    width: 7vw;
  }
}

@media only screen and (min-width: 1024px) {
  .navbar .navbar-massaia {
    margin: 2%;
    font-size: 2.8vw;
  }

  .navbar .navbar-menu {
    margin: 2%;
    width: 2.8vw;
  }
}
