body {
  overflow: hidden !important;
}

@media only screen and (min-width: 0px) {
  .authors-container {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
  }

  .header-container {
    grid-row: 1;
  }

  .authors-grid-container {
    grid-row: 2;
  }

  .authors-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 12vh 3.5vh 10vh 3.5vh;
  }

  .authors-image {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    width: 100%;
    height: auto;
    aspect-ratio: 3/3;
    object-fit: cover;
  }

  .authors-image.hidden {
    display: none;
  }

  .authors-controllers {
    position: relative;
    bottom: 15%;
    left: 55%;
    transform: translateX(-50%);
    color: white;
  }

  .author-name {
    font-size: 16px;
    font-family: "AkzidGroMed";
    color: white;
  }

  .authors-controllers button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 3%;
    right: 10%;
    margin-top: 0;
  }

  .authors-controllers button img {
    width: 18px;
    height: auto;
  }

  .authors-controllers.hidden {
    display: none;
  }

  .authors-info {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .authors-info div {
    overflow-y: scroll;
    max-height: 30vh;
  }

  .authors-info h3 {
    font-family: "AkzidGroMed";
    font-size: 16px;
    display: flex;
    align-self: baseline;
    margin-top: 2vh;
  }

  .authors-info p {
    font-family: "AkzidGroLigOsF";
    font-size: 16px;
    width: 100%;
    margin-top: 1vh;
  }

  .authors-info a {
    text-decoration: none;
    color: black !important;
    font-family: "AkzidGroMed";
  }

  .authors-info button {
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 3vh;
    margin-right: 8vw;
  }

  .authors-info button img {
    width: 22px;
    height: auto;
  }
}

@media only screen and (min-width: 650px) {
  .authors-info h3 {
    font-size: 25px;
  }
  .authors-info p {
    font-size: 20px;
  }

  .author-name {
    font-size: 25px;
  }

  .authors-controllers button img {
    width: 25px;
  }
}

@media only screen and (min-width: 1024px) {
  .authors-container {
    overflow: hidden;
  }

  .authors-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 15vh 0 0 0;
  }

  .authors-image.hidden {
    display: none;
  }

  .authors-controllers {
    position: relative;
    bottom: 40%;
    left: 60%;
    transform: translateX(-50%);
    color: white;
  }

  .author-name {
    font-size: 24px;
    font-family: "AkzidGroMed";
    color: white;
  }

  .authors-controllers button {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
    top: 0%;
    right: 0%;
    margin-top: 1vh;
  }

  .authors-controllers button img {
    width: 20px;
    height: auto;
  }

  .authors-controllers.hidden {
    display: none;
  }

  .authors-info {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .authors-info h3 {
    margin-top: 2rem;
    font-family: "AkzidGroMed";
    font-size: 20px;
    display: flex;
    align-self: baseline;
  }

  .authors-info p {
    font-family: "AkzidGroLigOsF";
    font-size: 20px;
    margin-top: 3vh;
    margin-right: 6.5vw;
    width: 86%;
  }

  .authors-info button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    margin-right: 7%;
  }

  .authors-info button img {
    width: 25px;
    height: auto;
  }

  .authors-info.helena {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10vh;
    width: 50%;
    padding: 2rem;
  }
  .authors-info.helena div {
    max-height: 60vh;
  }

  .authors-info.pedro {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 10vh;
    width: 50%;
    padding: 2rem;
    overflow: hidden;
  }

  .authors-info.pedro div {
    max-height: 60vh;
  }
}
