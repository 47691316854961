.loadingPage {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 0px) {
  .videoPlayer {
    width: 100%;
    height: 100vh;
    background-color: black;
    overflow: hidden;
    scrollbar-width: none;
  }

  /* Hide scrollbar for Webkit browsers (Chrome, Safari) */
  .videoPlayer::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  .videoPlayer {
    scrollbar-width: none; /* For Firefox */
  }

  /* Hide scrollbar for IE and Edge */
  .videoPlayer {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .eyes {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .eyes .img-eyes {
    margin-top: 10vh;
    width: 25vw;
    height: auto;
  }

  .construction-notice {
    display: none;
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: "AkzidGroLigOsF";
  }

  /* iOS-specific style (hidden) */
  .hidden {
    display: none;
  }

  .content h1 {
    font-size: 13vw;
    font-weight: normal;
  }

  .content p {
    font-size: 6vw;
  }

  .content img {
    display: none;
  }
}

@media only screen and (min-width: 590px) {
  .eyes .img-eyes {
    width: 15vw;
    height: auto;
  }

  .content h1 {
    font-size: 10vw;
  }

  .content p {
    font-size: 5vw;
  }
}

@media only screen and (min-width: 1024px) {
  .videoPlayer {
    overflow: hidden;
  }

  .eyes .img-eyes {
    display: none;
  }

  .content h1,
  .content p {
    display: none !important; /* Ensure this applies even in Safari */
  }

  .content img {
    display: block;
    width: 80%;
    height: auto;
    position: relative;
  }

  .content img.img1 {
    animation: img1 3s;
  }

  .content img.img2 {
    animation: img2 3s;
  }

  .content img.img3 {
    animation: img3 3s;
  }
}

/* Force Safari to properly respect display: none */
@supports (-webkit-touch-callout: none) {
  .content h1,
  .content p {
    display: none !important;
  }
}

/* Keyframes for image animations */
@keyframes img1 {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@keyframes img2 {
  0% {
    right: -100vw;
  }
  100% {
    right: 0;
  }
}

@keyframes img3 {
  0% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}
