body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: black;
}

@media only screen and (min-width: 0px) {
  .menu-geral {
    width: 100vw;
    height: 20vw;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    background-color: white;
  }

  .menu-geral img {
    right: 0;
  }

  .menu-container {
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-massaia {
    display: none;
  }

  .menu-exit {
    position: absolute;
    top: 0;
    right: 0;
    margin: 7%;
    width: 10vw;
    height: auto;
    z-index: 1;
  }

  .menu-options {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }

  .menu-options .menu-main-option {
    font-size: 10vw;
    cursor: pointer;
  }

  .menu-options .menu-main-option a {
    text-decoration: none;
    color: black;
    font-family: "AkzidGroReg";
  }

  .menu-options .menu-main-option p {
    margin: 0;
    padding: 0;
    font-family: "AkzidGroReg";
  }

  .menu-options .menu-main-option .menu-option button {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-decoration: none;
    color: black;
    font-family: "AkzidGroLigOsF";
  }

  .menu-options .menu-main-option .menu-option a {
    font-family: "AkzidGroLigOsF";
  }
}

@media only screen and (min-width: 590px) {
  .menu-massaia {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 6%;
    font-family: "AkzidGroLigOsF";
    font-size: 6vw;
    font-weight: normal;
  }

  .menu-exit {
    margin: 6%;
    width: 7vw;
  }

  .menu-options {
    padding: 0;
  }

  .menu-options .menu-main-option {
    font-size: 7vw;
  }
}

@media only screen and (min-width: 1024px) {
  .menu-geral {
    height: 15vh;
  }

  .menu-massaia {
    margin: 2%;
    font-size: 2.8vw;
  }

  .menu-exit {
    margin: 2%;
    width: 2.8vw;
  }

  .menu-options {
    align-items: start;
    margin-left: 5%;
  }

  .menu-options .menu-main-option {
    font-size: 2.8vw;
    width: 20%;
  }

  .menu-options .menu-main-option .menu-option {
    margin-left: 7%;
  }
}
