@media only screen and (min-width: 0px) {
  .project-container {
    margin-top: 12vh;
  }

  .project-video {
    width: 100vw;
    height: auto;
    margin-bottom: 2rem;
  }

  .project-scroll-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
    gap: 2rem;
  }

  .project-scroll-bar p {
    font-family: "AkzidGroMed";
    margin: 0 1rem;
    width: max-content;
    cursor: pointer;
    font-size: 15px;
  }

  .project-scroll-bar .project-key {
    margin: 0 10px;
    cursor: pointer;
    padding: 10px;
  }

  .project-scroll-bar .project-key.selected {
    background-color: black;
    color: white;
  }

  .project-scroll-bar .nav-arrow {
    width: 2rem;
    cursor: pointer;
    margin: 0 10px;
  }

  .project-scroll-bar .nav-arrow-prev {
    transform: rotate(180deg);
  }

  .project-info {
    padding: 2rem;
  }

  .project-info .sanitized-content p {
    font-family: "AkzidGroLigOsF";
    font-size: 18px;
  }

  .project-info a {
    text-decoration: none;
    color: black;
    font-family: "AkzidGroMed";
  }

  .project-info .logos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .project-info .logos img {
    width: 35vw;
    height: auto;
    margin: 2rem;
  }
}

@media only screen and (min-width: 880px) {
  .project-container {
    margin-top: 5vh;
  }

  .project-video {
    width: 100vw;
    height: 40vh;
    margin: 0;
  }

  .project-scroll-bar {
    justify-content: flex-start;
    margin: 2rem;
  }

  .project-scroll-bar .project-key {
    margin: 0;
  }

  .project-info {
    margin: 2rem;
    padding: 0 45% 0 0;
  }

  .project-info .sanitized-content p {
    margin: 0;
    font-size: 16px;
  }

  .project-info .logos {
    flex-direction: row;
    justify-content: flex-start;
  }

  .project-info .logos img {
    height: 6vh;
    width: auto;
    margin: 2rem 2rem 0 0;
  }
}
