.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.artists-container {
  margin-top: 10vh;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 5vh;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.grid-item img {
  width: 90vw;
  height: 90vw;
  object-fit: cover;
  cursor: pointer;
  filter: grayscale(100%);
}

.grid-item.active img {
  filter: grayscale(0);
  filter: brightness(0.7);
}

.artists_info {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 3vh;
  width: 90vw;
}
.artists_info p {
  margin: 0;
  font-family: "AkzidGroLigOsF";
  font-size: 18px;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (min-width: 769px) {
  .grid-item img {
    width: 100%;
    height: 200px;
  }

  .artists-container {
    margin-top: 15vh;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0;
  }

  .grid-item {
    margin: 0;
    position: relative;
  }

  .artists_info {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    color: white;
    display: none;
    margin: 0;
    padding: 10px;
  }

  .artists_info p {
    font-size: 20px;
    font-family: "AkzidGroMed";
  }

  .artists_info .artists-title {
    font-size: 14px;
  }

  .grid-item.active .artists_info {
    display: block;
  }
}
