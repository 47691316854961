.header-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.gallery-container {
  margin-top: 10vh;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 5vh;
}

.artPiece-grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.artPiece-grid-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.art_info {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 3vh;
  width: 90vw;
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 75vh;
  overflow-y: hidden;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.carousel-control-prev {
  height: 50px;
  left: 0;
  transform: translateY(-100%);
  top: 55%;
}

@media (min-width: 769px) {
  .gallery-container {
    margin-top: 15vh;
  }

  .carousel-container {
    display: flex;
  }

  .grid-container {
    display: none;
  }

  .display_art_pieces {
    width: 100%;
    height: 100%;
  }

  .art_piece img {
    width: 100vw;
    height: 80vh;
    object-fit: cover;
  }

  .carousel-item,
  .carousel-inner,
  .carousel {
    position: relative;
  }

  .carousel-caption {
    position: relative;
    bottom: 10rem;
    left: 63vw;
    background-color: white;
    padding: 10px;
    text-align: left;
    width: 20vw;
    height: max-content;
    /* border: 2px solid red; */
    max-width: 300px;
    z-index: 10;
    h3 {
      font-size: 1.5rem;
    }
  }

  .carousel-caption h3,
  .carousel-caption p {
    color: black;
  }

  .custom-modal-size {
    display: flex;
    align-items: center;
    justify-content: center !important;
    max-width: none;
    width: auto;
  }

  .custom-modal-size .modal-content {
    width: auto;
    height: auto;
    max-width: 100vw;
    max-height: 100vh;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-caption {
    left: 75%;
  }
}
