body {
  overflow: hidden;
}

@media only screen and (min-width: 0px) {
  .credits-info {
    position: absolute;
    top: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin: 0;
    background-color: white;
  }
}

.credits-info .img-eyes {
  width: 25vw;
  height: auto;
}

.credits-info h2 {
  padding: 5%;
  font-family: "AkzidGroLigOsF";
  font-size: 120%;
}

.credits-info .credits-roles-authors {
  display: block; /* Ensures the height property is respected */
  text-align: center;
  padding: 0 7%;
  font-family: "AkzidGroLigOsF";
  font-size: 4vw;
  height: 51vh;
  overflow-y: scroll; /* Always shows the scrollbar */
  scroll-behavior: smooth;
}

.credits-info .credits-roles-authors .role {
  font-family: "AkzidGroMed";
  display: inline;
}

.credits-info .credits-exit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5%;
}

.credits-info .credits-exit img {
  width: 10vw;
  height: auto;
  z-index: 1;
}

/* Custom scrollbar styling */
.credits-info .credits-roles-authors::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.credits-info .credits-roles-authors::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
}

.credits-info .credits-roles-authors::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
}

@media only screen and (min-width: 590px) {
  .credits-info .img-eyes {
    margin-top: 8vh;
    width: 15vw;
  }

  .credits-info h2 {
    font-size: 220%;
  }

  .credits-info .credits-roles-authors {
    font-size: 190%;
  }
  .credits-info .credits-exit img {
    width: 5vw;
  }
}

@media only screen and (min-width: 1024px) {
  .credits-info .img-eyes {
    margin-top: 8%;
    width: 5vw;
  }
  .credits-info h2 {
    padding: 2%;
    font-size: 1.5vw;
  }

  .credits-info .credits-roles-authors {
    padding: 0 25%;
    font-size: 1.3vw;
    height: 61vh;
  }

  .credits-info .credits-exit {
    margin: 2% 0;
  }

  .credits-info .credits-exit img {
    width: 2.5vw;
  }
}
