/* Global box-sizing for consistency */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Base styles */
body {
  overflow: hidden;
  margin: 0; /* Ensure no margin */
}

a {
  text-decoration: none;
  color: black;
}

/* Spinner container */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure it takes full height */
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Media Queries */
@media only screen and (min-width: 0px) {
  /* Lyrics Container */
  .lyrics-container {
    position: fixed; /* Use fixed to maintain visibility */
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    overflow-y: auto; /* Use auto for better scrolling */
    -webkit-overflow-scrolling: touch; /* Improve scroll performance in Safari */
  }

  .lyrics-content {
    margin-bottom: 5vh;
  }

  .lyrics-content p {
    margin: 0;
    font-size: 20px;
  }

  .lyrics-exit {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    width: 100vw;
    background-color: white;
  }

  .lyrics-exit img {
    align-self: center;
    margin: 2rem auto;
    height: 5vw;
    width: auto;
  }

  .massaia {
    display: none;
  }

  .menu {
    position: absolute;
    top: 0;
    right: 0;
    margin: 7%;
    z-index: 1;
  }

  .menu img {
    height: auto;
    width: 10vw;
  }

  .album-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .album-cover img {
    margin: 5px 0; /* Simplified margin */
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .album-cover p {
    margin: 0;
    font-family: "AkzidGroLigOsF";
    color: white;
  }

  /* Credits */
  .credits {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    margin: 7%;
    font-family: "AkzidGroLigOsF";
    z-index: 1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vw;
  }

  .credits p {
    font-size: 5vw;
    margin: 0;
  }
  .credits .sound {
    height: 5vw;
    width: auto;
  }

  .credits img {
    margin: 0;
    padding: 0;
    height: 4vw;
    width: auto;
  }

  .song-info {
    position: absolute;
    bottom: 0;
    color: white;
    margin: 7%;
    font-family: "AkzidGroLigOsF";
  }

  .song-info div {
    font-size: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2vw;
  }

  .song-info div .vol {
    margin: 0;
    font-size: 4.2vw;
    font-family: "AkzidGroReg";
    cursor: pointer;
  }

  .song-info .custom-dropdown {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    z-index: 1;
  }

  .song-info .custom-dropdown .dropdown-toggle {
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    color: white !important;
    font-family: "AkzidGroReg" !important;
    padding: 0;
    font-size: 4.2vw;
    z-index: 1;
  }

  .song-info .custom-dropdown .dropdown-menu {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    z-index: 1;
  }

  .song-info .custom-dropdown-item {
    background: none !important;
    border: none !important;
    border-radius: 0 !important;
    color: white !important;
    font-size: 4.2vw;
    font-family: "AkzidGroReg" !important;
    padding: 0 5px;
  }

  .song-info .custom-dropdown-item:hover,
  .song-info .custom-dropdown-item:focus {
    background: black !important;
    color: white !important;
  }

  /* Menu Albums */
  .menu-albums {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling in Safari */
  }

  .menu-albums .menu-albums-cover {
    width: 100px;
    height: 100px;
    object-fit: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 40vw;
  }

  .menu-albums .menu-albums-songs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .menu-albums .menu-albums-song {
    margin: 20px 0 0 1rem;
    width: 150px;
    height: 100px;
    object-fit: cover;
  }

  .menu-albums p {
    margin: 0 0 0 1rem;
    color: white;
    height: 20px;
    font-family: "AkzidGroLigOsF";
  }

  /* Scroll Menu */
  .scrollmenu {
    overflow-x: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; /* Firefox */

    /* WebKit browsers (Chrome, Safari, newer Edge) */
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
}

@media only screen and (min-width: 590px) {
  .lyrics-container .lyrics-content p {
    font-size: 20px;
  }

  .lyrics-container .lyrics-exit img {
    height: 4vw;
  }

  .massaia {
    position: absolute;
    top: 0;
    display: flex;
    margin: 7%;
    color: white;
    font-family: "AkzidGroLigOsF";
  }

  .massaia p {
    font-size: 6vw;
  }

  .album-cover img {
    width: 150px;
    height: 150px;
  }

  .menu img {
    width: 6vw;
  }

  .credits p {
    font-size: 4vw;
  }

  .credits img {
    width: 4vw;
  }
  .credits .sound {
    height: 4vw;
  }

  .song-info div {
    font-size: 4vw;
    gap: 2vw;
  }

  .song-info div .vol {
    font-size: 4vw;
  }

  .custom-dropdown .dropdown-toggle {
    font-size: 4vw;
    font-family: "AkzidGroLigOsF" !important;
  }

  .custom-dropdown-item {
    font-size: 3.5vw;
  }

  /* menu albums */
  .menu-albums .menu-albums-cover {
    width: 150px;
    height: 150px;
  }

  .menu-albums-song {
    width: 200px;
    height: 150px;
  }

  .menu-albuns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .menu-albums-line {
    position: absolute;
    top: 0;
    margin-top: 10%;
  }

  .menu-albums-line img {
    width: 150px;
    height: auto;
  }
}

@media only screen and (min-width: 1024px) {
  /* Further adjustments for very large screens */
  .lyrics-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 20vw;
    height: 100vh;
    overflow: auto; /* Ensure scrolling works */
  }

  .lyrics-container .lyrics-content p {
    padding: 0;
    font-size: 15px;
  }

  .lyrics-container .lyrics-exit {
    width: 20vw;
  }

  .lyrics-container .lyrics-exit img {
    margin: 2rem auto;
    height: 1.5vw;
    width: auto;
  }

  .massaia {
    margin: 2%;
  }

  .massaia p {
    font-size: 2.8vw;
  }

  .menu {
    margin: 2%;
  }

  .menu img {
    width: 2.8vw;
  }

  .credits {
    margin: 2%;
    gap: 1vw;
  }
  .credits p {
    font-size: 1.5vw;
  }

  .credits img {
    width: 1.5vw;
  }

  .credits .sound {
    height: 1.5vw;
  }

  .song-info {
    margin: 2%;
  }

  .song-info div {
    font-size: 1.5vw;
    gap: 1vw;
    margin-bottom: 2%;
  }

  .song-info div .vol {
    font-size: 1.5vw;
  }

  .song-info .custom-dropdown .dropdown-toggle {
    font-size: 1.5vw;
  }

  .song-info .custom-dropdown-item {
    font-size: 1.5vw;
  }
}

/* Adjust menu albums */
.menu-albums .menu-albums-cover {
  width: 150px;
  height: 150px;
}
