body {
  overflow-y: scroll !important;
}

@media only screen and (min-width: 0px) {
  .site-container {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .site-creator {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .site-img {
    width: 200px;
    border-radius: 100px;
    margin-bottom: 0.8rem;
  }

  .site-name {
    text-align: center;
    font-family: "AkzidGroMed";
    font-size: 25px;
    margin: 0;
  }

  .site-title {
    text-align: center;
    font-family: "AkzidGroLigOsF";
    font-size: 20px;
  }

  .site-description {
    margin: 2rem;
    font-family: "AkzidGroLigOsF";
    font-size: 20px;
  }
}

@media only screen and (min-width: 1039px) {
  .site-container {
    flex-direction: row;
    justify-content: center;
  }

  .site-creator {
    margin: 2rem 4rem;
  }

  .site-img {
    width: 150px;
    border-radius: 75px;
  }

  .site-name {
    font-size: 18px;
  }

  .site-title {
    font-size: 15px;
  }

  .site-description {
    padding: 0 45% 0 0;
    margin: 0 0 8vh 2rem;
  }
}
